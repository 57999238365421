import { ReactElement, ReactNode, useMemo } from "react";
import { EMPTY_ARR, addBase64Prefix } from "../../../util/shared.util";
import styles from "./DriverVehicleTable.module.scss";
import Table from "../../Table/Table";
import { Driver } from "../../../api/data-contracts";
import ActionButton from "../../Buttons/ActionButton/ActionButton";
import { FormattedMessage } from "react-intl";
import SortingHeaderContainer from "../../SortingHeaderContainer/SortingHeaderContainer";
import { DriversSortFilterParamName } from "../../../services/driver.service";
import PhotoWithOverlay from "../PhotoOverlay";
import DriverPhotoPlaceholder from "../../../assets/DriverPhotoPceholder.svg";
import { CellProps, Column } from "react-table";
import { ReactComponent as DriverIcon } from "../../../assets/icons/Driver.svg";
import { DRIVERS_CONTEXT } from "../../../context/QueryParamsContext/queryParamContextObjects";

export interface DriverTableParams {
  onClick(vehicle: Driver): Promise<void>;
  buttonLabel: ReactElement;
  buttonIcon: ReactNode;
  data: Driver[] | undefined;
  isLoading: boolean | undefined;
  isError: boolean | undefined;
  isFetching: boolean | undefined;
}

export const DriverVehicleTable = ({ data, onClick, isError, isLoading, buttonLabel, buttonIcon }: DriverTableParams) => {

  const columns = useMemo(() => generateColumns(buttonLabel, buttonIcon, onClick), [onClick, buttonLabel, buttonIcon]);

  return (
    <div className="d-flex flex-column">
      <div className="px-1 py-2">
        <Table
          data={data || EMPTY_ARR}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            tr: () => styles.tr,
            td: () => styles.td,
          }}
          isLoading={isLoading}
          isError={isError}
        />
      </div>

    </div>
  );
};

export default DriverVehicleTable;
function generateColumns(buttonLabel: ReactElement, buttonIcon: ReactNode, onClick: (vehicle: Driver) => void): Column<Driver>[] {
  return [
    {
      id: "icon",
      Header: <FormattedMessage id="DRIVER" defaultMessage="Driver" />,
      Cell: () => <div className="d-flex justify-content-center">
        <DriverIcon fill="black" />
      </div>
    },
    {
      accessor: "image",
      Header: <FormattedMessage id="PHOTO" defaultMessage="Photo" />,
      Cell: ({ value }) => (
        <div className="d-flex align-items-center gap-4">
          <PhotoWithOverlay src={(value && addBase64Prefix(value)) || DriverPhotoPlaceholder} />
        </div>
      )
    },
    {
      accessor: "name",
      Header: (
        <SortingHeaderContainer<DriversSortFilterParamName> context={DRIVERS_CONTEXT} paramName="name">
          <FormattedMessage id="NAME" defaultMessage="Name" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {

        return <div>{value}</div>;
      }
    },
    {
      accessor: "driverId",
      Header: (
        <SortingHeaderContainer<DriversSortFilterParamName> context={DRIVERS_CONTEXT} paramName="driverId">
          <FormattedMessage id="DRIVER_ID" defaultMessage="Driver ID" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        return <div>{value}</div>;
      }
    },
    {
      id: "button",
      Cell: ({ row }: CellProps<Driver, any>) => (
        <ActionButton disabled={false} type="button" icon={buttonIcon} onClick={() => onClick(row.original)}>
          <>
            {buttonLabel}
          </>
        </ActionButton>)
    },
  ];
}

