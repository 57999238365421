import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { GetConfigurationParams, ConfigSortFilterParamName } from "../../services/configuration.service";
import { PropsWithChildren, useCallback } from "react";
import { Constants } from "../../constants";
import { CONFIGS_CONTEXT } from "./queryParamContextObjects";
import { FilterOperator } from "../../models/requests/shared.request";

const validConfigSortFilterParamNames: ConfigSortFilterParamName[] = ["name", "version", "creationDate", "tspId"];

export function ConfigQueryParamsContextProvider({ children }: PropsWithChildren) {
  const initialParams: GetConfigurationParams = {
    filters: [],
    sorts: [],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
  };

  return <QueryParamsContextProvider context={CONFIGS_CONTEXT} initialParams={initialParams}>{children}</QueryParamsContextProvider>;
}

export const useConfigQueryParamsContext = () => {
  const ctx = useQueryParamsContext<ConfigSortFilterParamName, ConfigSortFilterParamName>(CONFIGS_CONTEXT);
  const { upsertFilter } = ctx;

  const upFilter = useCallback((paramName: ConfigSortFilterParamName, operator: FilterOperator, values: string[]) => {
    if (validConfigSortFilterParamNames.includes(paramName)) {
      upsertFilter(paramName, operator, values);
    }
  }, [upsertFilter])

  return { ...ctx, upsertFilter: upFilter };
}

