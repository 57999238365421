import { QueryFunctionContext } from "@tanstack/react-query";
import { GetTripsParams, GetTripsResult, tripsService } from "../services/trips.service";

export function tripsQuery<selectedT>(
  params: GetTripsParams,
  selector: (queryResult: GetTripsResult) => selectedT,
  enabled: boolean
) {
  return {
    queryKey: ["trips", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["trips", GetTripsParams]>) =>
      await tripsService.getTrips(context.queryKey[1]),
    select: selector,
    enabled: enabled,
    retry: false
  };
}
