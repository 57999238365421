import { Color } from "../constants";
import ADASForwardCollision from "../assets/eventsNew/ADASForwardCollision.svg";
import ADASLaneDepartureWarning from "../assets/eventsNew/ADASLaneDepartureWarning.svg";
import ADASPedestrianCollisionWarning from "../assets/eventsNew/PedestrianCollision.svg";
import HighEventDistraction from "../assets/eventsNew/Driverdistracted.svg";
import HighEventDrowsiness from "../assets/eventsNew/Drowsiness.svg";
import HighEventPhoneuse from "../assets/eventsNew/Phoneuse.svg";
import Highsleep from "../assets/eventsNew/Asleep.svg";
import Highsmoking from "../assets/eventsNew/Smoking.svg";
import Low3DAccelerometerCalibrationCompleted from "../assets/eventsNew/3DAccelerometerCalibrationCompleted.svg";
import LowCameraCalibrationCompleted from "../assets/eventsNew/CameraCalibirationComplited.svg";
import LowEnteringSleepMode from "../assets/eventsNew/EnteringSleepMode.svg";
import LowFwUpdateStarted from "../assets/eventsNew/FwUpdateStarted.svg";
import LowGpsStatusUpdate from "../assets/eventsNew/GPSStatusUpdate.svg";
import LowHeartbeat from "../assets/eventsNew/HeartBeat.svg";
import LowIgnitionON from "../assets/eventsNew/IgnitionOn.svg";
import LowIgnitionOff from "../assets/eventsNew/IgnitionOff.svg";
import LowSystemBootOK from "../assets/eventsNew/SystemBookOK.svg";
import LowSystemOk from "../assets/eventsNew/SystemOK.svg";
import LowSystemReset from "../assets/eventsNew/SystemReset.svg";
import MeduimApplicationError from "../assets/eventsNew/ApplicationError.svg";
import MeduimCameraCalibrationFailed from "../assets/eventsNew/CameraCalibirationFaild.svg";
import MeduimCameraError from "../assets/eventsNew/CameraError.svg";
import MeduimDriverIdentified from "../assets/eventsNew/DriverIdentifier.svg";
import MeduimExternal from "../assets/eventsNew/ExternalEventTriggering.svg";
import MeduimFwUpdateCompleted from "../assets/eventsNew/FwUpdateComplited.svg";
import MeduimFwUpdateFailed from "../assets/eventsNew/FwUpdateFaild.svg";
import MeduimMovementStarted from "../assets/eventsNew/MovementStarted.svg";
import MeduimMovementStopped from "../assets/eventsNew/MovementStoped.svg";
import MeduimSystemBootFailure from "../assets/eventsNew/SystemBootFailure.svg";
import MeduimSystemError from "../assets/eventsNew/SystemError.svg";
import MeduimTailgating from "../assets/eventsNew/Tailgating.svg";
import MeduimUnidentifiedDriver from "../assets/eventsNew/UnidentifiedUnauthorizedDriver.svg";
import MeduimUrbanForwardCollisionWarning from "../assets/eventsNew/UrbanForwardCollision.svg";
import Seatbelt from "../assets/eventsNew/Seatbelt.svg";
import DefaultEvent from "../assets/eventsNew/GenericEvent.svg";
import { Node as TNode } from "react-checkbox-tree";
import HighTamperingDetection from "../assets/eventsNew/TamperingDetection.svg";
import HighDriverDisappeared from "../assets/eventsNew/DriverDisappeard.svg";
import LowDriverIDUpdated from "../assets/eventsNew/DriverIDUpdated.svg";

const eventServerNameToEventMeta = {
  DriverDistraction: {
    displayName: "Driver Distraction",
    initials: "DMS",
    severity: "Medium",
    category: "Safety events",
    img: HighEventDistraction,
  },
  DriverDrowsiness: {
    displayName: "Driver Drowsiness",
    initials: "DMS",
    severity: "Medium",
    category: "Safety events",
    img: HighEventDrowsiness,
  },
  DriverAsleep: {
    displayName: "Driver Asleep",
    initials: "DMS",
    severity: "High",
    category: "Safety events",
    img: Highsleep,
  },
  PhoneUse: {
    displayName: "Phone Use",
    initials: "DMS",
    severity: "High",
    category: "Safety events",
    img: HighEventPhoneuse,
  },
  DriverSmoking: {
    displayName: "Driver Smoking",
    initials: "DMS",
    severity: "High",
    category: "Safety events",
    img: Highsmoking,
  },
  UnfastenedSeatbelt: {
    displayName: "Seatbelt",
    initials: "DMS",
    severity: "High",
    category: "Safety events",
    img: Seatbelt,
  },
  ExternalEvent: {
    displayName: "External Event",
    initials: "Multiple",
    severity: "Medium",
    category: "Safety events",
    img: MeduimExternal,
  },
  TamperingDetection: {
    displayName: "Tampering Detection",
    initials: "FS10",
    severity: "High",
    category: "Safety events",
    img: HighTamperingDetection,
  },
  FCW: {
    displayName: "Forward Collision Warning (FCW)",
    initials: "ADAS",
    severity: "High",
    category: "Safety events",
    img: ADASForwardCollision,
  },
  HMW: {
    displayName: "Tailgating (HMW)",
    initials: "ADAS",
    severity: "Medium",
    category: "Safety events",
    img: MeduimTailgating,
  },
  LDW: {
    displayName: "Lane Departure Warning (LDW)",
    initials: "ADAS",
    severity: "Low",
    category: "Safety events",
    img: ADASLaneDepartureWarning,
  },
  PCW: {
    displayName: "Pedestrian Collision Warning (PCW)",
    initials: "ADAS",
    severity: "High",
    category: "Safety events",
    img: ADASPedestrianCollisionWarning,
  },
  UFCW: {
    displayName: "Urban Forward Collision Warning (UFCW)",
    initials: "ADAS",
    severity: "Medium",
    category: "Safety events",
    img: MeduimUrbanForwardCollisionWarning,
  },
  IgnitionON: {
    displayName: "Ignition ON",
    initials: "Vehicle",
    severity: "Low",
    category: "Vehicle events",
    img: LowIgnitionON,
  },
  DriverIdentified: {
    displayName: "Driver Identified",
    initials: "DMS",
    severity: "Medium",
    category: "Safety events",
    img: MeduimDriverIdentified,
  },
  IgnitionOFF: {
    displayName: "Ignition Off",
    initials: "Vehicle",
    severity: "Low",
    category: "Vehicle events",
    img: LowIgnitionOff,
  },
  MovementStarted: {
    displayName: "Movement Started",
    initials: "FS10",
    severity: "Medium",
    category: "FS10 Device events",
    img: MeduimMovementStarted,
  },
  MovementStopped: {
    displayName: "Movement Stopped",
    initials: "FS10",
    severity: "Medium",
    category: "FS10 Device events",
    img: MeduimMovementStopped,
  },
  UnidentifiedUnauthDriver: {
    displayName: "Unidentified unauthorized driver",
    initials: "DMS",
    severity: "High",
    category: "Safety events",
    img: MeduimUnidentifiedDriver,
  },
  DriverDisappeared: {
    displayName: "Driver Disappeared",
    initials: "DMS",
    severity: "High",
    category: "Safety events",
    img: HighDriverDisappeared,
  },
  SystemBootOK: {
    displayName: "System Boot OK",
    initials: "FS10",
    severity: "Low",
    category: "FS10 Device events",
    img: LowSystemBootOK,
  },
  SystemBootFailure: {
    displayName: "System Boot Failure",
    initials: "FS10",
    severity: "Medium",
    category: "FS10 Device events",
    img: MeduimSystemBootFailure,
  },
  SystemReset: {
    displayName: "System Reset",
    initials: "FS10",
    severity: "Low",
    category: "FS10 Device events",
    img: LowSystemReset,
  },
  EnteringSleepMode: {
    displayName: "Entering Sleep Mode",
    initials: "FS10",
    severity: "Low",
    category: "FS10 Device events",
    img: LowEnteringSleepMode,
  },
  SystemError: {
    displayName: "System Error",
    initials: "FS10",
    severity: "Medium",
    category: "FS10 Device events",
    img: MeduimSystemError,
  },
  SystemOK: {
    displayName: "System OK",
    initials: "FS10",
    severity: "Low",
    category: "FS10 Device events",
    img: LowSystemOk,
  },
  CalibrationCompleted: {
    displayName: "Calibration Completed",
    initials: "FS10",
    severity: "Low",
    category: "FS10 Device events",
    img: LowCameraCalibrationCompleted,
  },
  "3DAccelerometerCalibration Completed": {
    displayName: "Accelerometer Calibration Completed",
    initials: "FS10",
    severity: "Low",
    category: "FS10 Device events",
    img: Low3DAccelerometerCalibrationCompleted,
  },
  DriverIDUpdated: {
    displayName: "Driver ID Updated",
    initials: "DMS",
    severity: "Low",
    category: "Safety events",
    img: LowDriverIDUpdated,
  },
  GpsStatusUpdate: {
    displayName: "GPS Status Update",
    initials: "FS10",
    severity: "Low",
    category: "FS10 Device events",
    img: LowGpsStatusUpdate,
  },
  CameraCalibrationFailed: {
    displayName: "Camera Calibration Failed",
    initials: "FS10",
    severity: "Medium",
    category: "FS10 Device events",
    img: MeduimCameraCalibrationFailed,
  },
  FwUpdateStarted: {
    displayName: "FW Update Started",
    initials: "FS10",
    severity: "Low",
    category: "FS10 Device events",
    img: LowFwUpdateStarted,
  },
  FwUpdateCompleted: {
    displayName: "FW Update Completed",
    initials: "FS10",
    severity: "Medium",
    category: "FS10 Device events",
    img: MeduimFwUpdateCompleted,
  },
  FwUpdateFailed: {
    displayName: "FW Update Failed",
    initials: "FS10",
    severity: "Medium",
    category: "FS10 Device events",
    img: MeduimFwUpdateFailed,
  },
  ApplicationError: {
    displayName: "Application Error",
    initials: "FS10",
    severity: "Medium",
    category: "FS10 Device events",
    img: MeduimApplicationError,
  },
  CameraError: {
    displayName: "Camera Error",
    initials: "FS10",
    severity: "Medium",
    category: "FS10 Device events",
    img: MeduimCameraError,
  },
  Heartbeat: {
    displayName: "Heartbeat",
    initials: "FS11",
    severity: "Low",
    category: "FS10 Device events",
    img: LowHeartbeat,
  },
  UnknownDriver: {
    displayName: "Unknown Driver",
    initials: "FS10",
    severity: "Medium",
    category: "Safety events",
    img: MeduimUnidentifiedDriver,
  },
  MCULOG: {
    displayName: "MCU LOG",
    initials: "FS10",
    severity: "Low",
    category: "FS10 Device events",
    img: DefaultEvent,
  },
  OrientationSaved: {
    displayName: "Orientation Saved",
    initials: "FS10",
    severity: "Low",
    category: "FS10 Device events",
    img: DefaultEvent,
  },
  DriverChanged: {
    displayName: "Driver Changed",
    initials: "FS10",
    severity: "Medium",
    category: "Safety events",
    img: DefaultEvent,
  },
} as const;

const defaultEvent = {
  displayName: "DefaultEvent",
  initials: "FS10",
  severity: "Medium",
  category: "FS10 Device events",
  img: DefaultEvent,
} as const;

type StringToDeviceEvent = { [key: string]: EventMeta | undefined };

export type EventServerName = keyof typeof eventServerNameToEventMeta;
export type EventMeta = (typeof eventServerNameToEventMeta)[EventServerName];
export type EventSeverity = EventMeta["severity"];
export type EventCategory = EventMeta["category"];

export function getEventMetadata(serverName?: string) {
  serverName = serverName ? serverName : "";
  return (
    (eventServerNameToEventMeta as StringToDeviceEvent)[serverName] ?? { ...defaultEvent, displayName: serverName }
  );
}

export function getEventColorBySeverity(severity?: EventSeverity) {
  switch (severity) {
    case "Medium":
      return Color.MEDIUM_SEVERITY;
    case "Low":
      return Color.LOW_SEVERITY;
    case "High":
      return Color.HIGH_SEVERITY;
    default:
      return Color.MEDIUM_SEVERITY;
  }
}

export function getEventColorByName(serverName: string | undefined) {
  let severity: EventSeverity = "Medium";
  if (serverName) {
    severity = getEventMetadata(serverName)?.severity ?? "Medium";
  }
  return getEventColorBySeverity(severity);
}

export function getEventsCheckboxTree(): TNode[] {
  const categoryNodes: TNode[] = [];
  const categoryNameToNode: Map<EventCategory, TNode> = new Map();

  for (const [serverName, eventMeta] of Object.entries(eventServerNameToEventMeta)) {
    const categoryName = eventMeta.category;

    if (!categoryNameToNode.has(categoryName)) {
      const newCategoryNode: TNode = { label: categoryName, value: categoryName, children: [] };
      categoryNodes.push(newCategoryNode);
      categoryNameToNode.set(categoryName, newCategoryNode);
    }
    categoryNameToNode.get(categoryName)?.children?.push({ label: eventMeta.displayName, value: serverName });
  }

  return [{ label: "All", value: "", children: categoryNodes }];
}

export const EVENTS_TYPES_COUNT = Object.keys(eventServerNameToEventMeta).length;
