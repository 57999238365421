import { Messages } from "../api/Messages";
import { EventMessage, MediaSource, MediaType, TargetUnit } from "../api/data-contracts";
import { Constants } from "../constants";
import { AuthState } from "../context/AuthContext";
import {
  FilterParam,
  NullablePagingQueryParams,
  QueryParams,
  ServiceGetResult,
} from "../models/requests/shared.request";
import { addAuthHandling } from "../util/api.util";
import { filterParamsToString } from "../util/filtering.util";
import LocalStorage from "../util/local-storage.util";
import { getNumberOfPages } from "../util/pagination.util";
import { sortParamsToString } from "../util/sorting.util";

export const eventsApi = new Messages();
addAuthHandling(eventsApi);

export type EventsSortAndFilterParamNames =
  | "driverId"
  | "tripId"
  | "eventType"
  | "eventDateTime"
  | "licensePlate"
  | "deviceID"
  | "tspId"
  | "fleetId"
  | "speed"
  | "vehicleId"
  | "category"
  | "status";

export type EventsSortParam = {
  param: EventsSortAndFilterParamNames;
  descending?: boolean;
};

export type EventsFilterParam = FilterParam<EventsSortAndFilterParamNames>;

export type GetEventsParams = QueryParams<EventsSortParam, EventsFilterParam>;

export type NullablePagingGetEventParams = NullablePagingQueryParams<EventsSortParam, EventsFilterParam>;

export type GetEventsResult = ServiceGetResult<EventMessage[]>;

export interface GetMediaParams {
  messageId: number;
  deviceId: string;
  type: MediaType;
  source: MediaSource;
  index: number; //zero-based
}

export const eventsService = {
  getEvents: async ({ paging, sorts, filters, geocoding }: NullablePagingGetEventParams): Promise<GetEventsResult> => {
    const result = await eventsApi.getEvents({
      sorts: sorts.length ? sortParamsToString(sorts) : undefined,
      filters: filters.length ? filterParamsToString(filters) : undefined,
      page: paging?.pageIndex,
      pageSize: paging?.pageSize,
      geocoding,
    });

    const header = result.data.header;
    let pageCount: number | undefined = undefined;
    if (header && header.pageSize && header.totalCount !== undefined) {
      pageCount = getNumberOfPages(header.totalCount, header.pageSize);
    }

    return {
      data: result.data.data,
      pageCount,
      totalCount: header?.totalCount,
    };
  },

  getEventTypes: async () => {
    const response = await eventsApi.getEventTypes();

    return response.data.data;
  },

  getUrlForMedia: ({ messageId, deviceId, type, source, index }: GetMediaParams): string => {
    const token = LocalStorage.get<AuthState>(Constants.AuthStorageKey)?.token;
    return `${eventsApi.instance.defaults.baseURL}/message/${type}/${TargetUnit.Device}/${deviceId}/${messageId}?source=${source}&index=${index}&access_token=${token}`;
  },
};
