import { ReactElement, ReactNode, useMemo } from "react";
import { generateColumns } from "./driver-vehicles-table.util";
import { EMPTY_ARR } from "../../../util/shared.util";
import styles from "./DriverVehiclesTable.module.scss";
import Table from "../../Table/Table";
import { Row } from "react-table";


export interface DriverDeviceEntry {
  vehicleType?: string;
  photo?: string;
  licensePlate?: string;
  deviceId?: string;
  button?: string;
  existInDriver: boolean;
  driverCount?: number
}

export interface DriverDeviceParams {
  onClick?: (device: DriverDeviceEntry) => Promise<void>;
  buttonLabel?: ReactElement;
  buttonIcon?: ReactNode;
  data: DriverDeviceEntry[] | undefined;
  isLoading: boolean | undefined;
  isError: boolean | undefined;
  isFetching: boolean | undefined;
  onClickRow?: (device?: DriverDeviceEntry)=> void,
  alertDriverLimit: boolean
}

export const DriverDeviceTable = ({ data, onClick, isError, isLoading, buttonLabel, buttonIcon,onClickRow, alertDriverLimit }: DriverDeviceParams) => {

  const columns = useMemo(() => generateColumns(buttonLabel, buttonIcon, onClick,alertDriverLimit), [onClick,buttonLabel,buttonIcon, alertDriverLimit]);
  const onRowClicked = (row: Row<DriverDeviceEntry>)=>{
    onClickRow && onClickRow(row?.original);
  }
  return (
    <div className="d-flex flex-column">
      <div className="px-1 py-2">
        <Table
          data={data || EMPTY_ARR}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            tr: (row) => row.original.existInDriver ? styles["tr-disabled"] : styles.tr,
            td: () => styles.td,
          }}
          isLoading={isLoading}
          isError={isError}
          onClickRow={row => onRowClicked(row)}
        />
      </div>
      
    </div>
  );
};

export default DriverDeviceTable;
