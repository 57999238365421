import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToastContext } from "../context/ToastContext";
import {
  ConfigParams,
  addConfigMutation,
  deleteConfigMutation,
  enableOrDisableConfigMutation,
} from "../mutations/configuration.mutation";

interface UseConfigProps {
  onSuccessDeleteConfig?: () => void;
  onSuccessAddConfig?: () => void;

}

function useConfiguration({ onSuccessDeleteConfig,onSuccessAddConfig }: UseConfigProps = {}) {
  const { add: addToast } = useToastContext();
  const queryClient = useQueryClient();


  const {
    mutate: addDeviceConfig,
  } = useMutation(addConfigMutation(
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["configuration"] });
          addToast({ type: "POSITIVE", content: `Config add Success` });
          onSuccessAddConfig?.();
        },
        onError: (error) => {
          let { response } = error;
          let data = response?.data as any;
          let message = data?.Exception?.Message;
          addToast({ type: "NEGATIVE", content: `Config add Failed ${message ? message : ""}` });
        },
      }
    
  ));

  const { mutate: mutateDeleteConfig } = useMutation(
    deleteConfigMutation({
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["configuration"] });
        addToast({ type: "POSITIVE", content: `Configuration deleted Successfully` });
        onSuccessDeleteConfig?.();
      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: `Failed deleting Configuration` });
      },
    })
  );


  const { mutate: muteEnableDisableConfig } = useMutation(
    enableOrDisableConfigMutation({
      onSuccess: () => {
        addToast({ type: "POSITIVE", content: `Configuration status changed Successfully` });
        queryClient.invalidateQueries({ queryKey: ["configuration"] });

      },
      onError: () => {
        addToast({ type: "NEGATIVE", content: `Failed change status Configuration` });
      },
    })
  );


  async function deleteConfigs(configs: ConfigParams[]): Promise<void> {
    for (let index = 0; index < configs.length; index++) {
      await mutateDeleteConfig(
        configs[index],
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["configuration"] });
          },
        }
      );
    }
  }

  return { deleteConfigs, addDeviceConfig,muteEnableDisableConfig };
}

export default useConfiguration;
