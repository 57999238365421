import { InputUi } from "../../../constants";
import DateTimeSelectFilter from "../../Filters/DateTimeSelectFilter";
import Pagination from "../../Table/Pagination";
import TripsList from "../TripsList/TripsList";
import { EVENTS_CONTEXT } from "../../../context/QueryParamsContext/queryParamContextObjects";
import { useTripsQueryParamsContext } from "../../../context/QueryParamsContext/TripsQueryParamsContextProvider";
import useQueryTrips from "../../../hooks/useQueryTrips";
import { toTripItem, tripsQueryResultToTableData } from "../trips-utils";

type DriverTripsProps = {
  onClickOnTripView: (tripId: number) => void;
};

export default function DriverTrips({ onClickOnTripView }: DriverTripsProps) {
  const { queryParams, setPageIndex } = useTripsQueryParamsContext();
  const { data: trips, isLoading } = useQueryTrips(tripsQueryResultToTableData);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
        <DateTimeSelectFilter context={EVENTS_CONTEXT} paramName="eventDateTime" width={InputUi.SINGLE_STD_WIDTH} />
      </div>
      <TripsList onClickOnTripView={onClickOnTripView} items={toTripItem(trips?.data)}
      />

      <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        onChangePageIndex={setPageIndex}
        pageCount={trips?.pageCount}
        allDisabled={isLoading}
        totalEntries={trips?.totalCount}
      />
    </>
  );
}
