import { SxProps } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { ReactNode, useCallback, useState } from "react";
import DateTime from "../../assets/DateTime.svg";
import { Constants, InputUi } from "../../constants";
import { IQueryParamsContext } from "../../context/QueryParamsContext/QueryParamsContext";
import useFilter from "../../hooks/useFilter";
import { useProfile } from "../../hooks/useProfile";
import { FilterOperator } from "../../models/requests/shared.request";
import { dateUtils, getFrontDtFormat } from "../../util/date.util";

const sx: SxProps = {
  "& .MuiInputBase-root": {
    height: InputUi.HEIGHT,
    "& input": {
      fontSize: "0.875rem",
      letterSpacing: "normal",
    },
  },
  "& .MuiInputAdornment-root": {
    marginLeft: "0",
  },
};

type DatePickerFilterProps<paramName extends string> = {
  context: React.Context<IQueryParamsContext<any, any>>;
  paramName: paramName;
  operator: FilterOperator;
  className?: string;
  label?: ReactNode;
  width?: string;
};

function DatePickerFilter<ParamName extends string>({
  context,
  paramName,
  operator,
  className,
  label,
}: DatePickerFilterProps<ParamName>) {
  const { dtFormat } = useProfile();
  const onForcedClear = useCallback(() => {
    setValue(null);
  }, []);
 
  const format = getFrontDtFormat(dtFormat)?.date ?? Constants.DEFAULT_FRONT_DT_FORMAT.date;
  const { upsertOrRemoveFilter, contextValues } = useFilter<ParamName>(context, paramName, operator, onForcedClear);
  const [value, setValue] = useState<Dayjs | null>(contextValues[0] ? dayjs(contextValues[0]) : null);
  
  return (
    <div className={`d-flex gap-2 align-items-center ${className}`}>
      <div className="input-with-label-container">
        <div className="input-label">{label}</div>
        <DatePicker
          openTo="year"
          slots={{
            openPickerIcon: () => <img src={DateTime} alt="date-time"></img>,
          }}
          label=" "
          format={format}
          sx={sx}
          value={value}
          views={["year", "month"]}
          onChange={(dt) => {
            let newDt = dt?.set("date",1)
            setValue(newDt ?? null);
            var dateOnly = newDt ? dateUtils.getFormattedDate(newDt.toDate(), format): undefined
            upsertOrRemoveFilter(dateOnly);
          }}
        />
      </div>
    </div>
  );
}

export default DatePickerFilter;
