import { FormattedMessage } from "react-intl";
import { ReactComponent as TrashBigIcon } from "../../../assets/icons/TrashBig.svg";

import ModalYesNo from "../../ModalYesNo/ModalYesNo";
import { DriverTableEntry } from "../DriversTable";
import useDriver from "../../../hooks/useDriver";

type DeleteModalProps = {
  show: boolean;
  onClickYes: () => void;
  onClickNo: () => void;
  driversData: DriverTableEntry[];
};

function DeleteUserModal({ show, onClickNo, onClickYes, driversData }: DeleteModalProps) {
  const { deleteDrivers } = useDriver();
  return (
    <ModalYesNo
      show={show}
      onClickNo={onClickNo}
      onClickYes={async () => {
        await deleteDrivers(driversData);
        onClickYes();
      }}
      onHide={onClickNo}
      body={
        <div className="d-flex flex-column align-items-center gap-3">
          <TrashBigIcon />
          <FormattedMessage id="DRIVER.DELETE_QUESTION" defaultMessage="Are you sure you want to delete this driver?" />
        </div>
      }
    />
  );
}

export default DeleteUserModal;
