import { CellProps, Column, Row, TableInstance } from "react-table";
import { Color } from "../../constants"; //TODO
import { DriverTableEntry } from "./DriversTable";
import { GetDriversResult, DriversSortFilterParamName } from "../../services/driver.service";
import { FormattedMessage } from "react-intl";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import SortingHeaderContainer from "../SortingHeaderContainer/SortingHeaderContainer";
import DriverPhotoPceholder from "../../assets/DriverPhotoPceholder.svg";
import PhotoWithOverlay from "../VehiclesTable/PhotoOverlay";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";
import { addBase64Prefix } from "../../util/shared.util";
import { DRIVERS_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";

export function queryResultToTableData(drivers: GetDriversResult) {
  return {
    pageCount: drivers.pageCount,
    totalCount: drivers.totalCount,
    data: drivers.data?.map(
      (driver): DriverTableEntry => ({
        name: driver.name,
        driverId: driver.driverId!,
        image: driver.image && addBase64Prefix(driver.image),
        vector: driver.vector,
        fleet: driver.fleetName,
        eventMessageTime: driver.lastEventMessageTime,
        lastEventType: driver.lastEventMessageType,
        lastTripId: driver.lastEventMessageTripId,
        fleetId: driver.fleetId!,
        tspId: driver.tspId!
      })
    ),
  };
}

export function generateDriversColumns(): Column<DriverTableEntry>[] {
  return [
    {
      id: "checkbox",
      Cell: ({ row }: CellProps<DriverTableEntry>) => (
        <CheckboxInput
          checked={row.isSelected ? "FULL" : "EMPTY"}
          onClick={() => row.toggleRowSelected(!row.isSelected)}
        />
      ),
    },
    {
      accessor: "driverId",
      Header: (
        <SortingHeaderContainer<DriversSortFilterParamName> context={DRIVERS_CONTEXT} paramName="driverId">
          <FormattedMessage id="DRIVER_ID" defaultMessage="Driver ID" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }: CellProps<DriverTableEntry>) => {
        return <div style={{ color: Color.CIPIA_BLUE }}>{value}</div>;
      },
    },
    {
      accessor: "image",
      Header: <FormattedMessage id="PROFILE_PHOTO" defaultMessage="Profile Photo" />,
      Cell: ({ value }) => (
        <div className="d-flex align-items-center gap-4">
          <PhotoWithOverlay src={value || DriverPhotoPceholder} />
        </div>
      ),
    },
    {
      accessor: "name",
      Header: (
        <SortingHeaderContainer<DriversSortFilterParamName> context={DRIVERS_CONTEXT} paramName="name">
          <FormattedMessage id="NAME" defaultMessage="Name" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "lastEventType",
      Header: (
        <SortingHeaderContainer<DriversSortFilterParamName> context={DRIVERS_CONTEXT} paramName="eventMessageType">
          <FormattedMessage id="LAST_EVENT" defaultMessage="Last Event" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "eventMessageTime",
      Header: (
        <SortingHeaderContainer<DriversSortFilterParamName> context={DRIVERS_CONTEXT} paramName="eventMessageTime">
          <FormattedMessage id="LAST_EVENT_TIME" defaultMessage="Event date and time" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        const { getFormattedLocalDate } = useFormattedLocalDate();
        return !value ? null : <div>{getFormattedLocalDate(value)}</div>;
      },
    },
    {
      accessor: "lastTripId",
      Header: (
        <SortingHeaderContainer<DriversSortFilterParamName> context={DRIVERS_CONTEXT} paramName="eventMessageTrip">
          <FormattedMessage id="LAST_TRIP" defaultMessage="Trip ID" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "fleet",
      Header: (
        <SortingHeaderContainer<DriversSortFilterParamName> context={DRIVERS_CONTEXT} paramName="fleetName">
          <FormattedMessage id="FLEET" defaultMessage="FLEET" />
        </SortingHeaderContainer>
      ),
    },
  ];
}

export function onClickRow(row: Row<DriverTableEntry>, table: TableInstance<DriverTableEntry>) {
  const isRowExpanded = row.isExpanded;
  table.toggleAllRowsExpanded(false);
  if (!isRowExpanded) {
    row.toggleRowExpanded(true);
  }

   
}

