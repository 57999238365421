import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { CellProps, Column } from "react-table";
import { Color } from "../../constants";
import { useDevicesQueryParamsContext } from "../../context/QueryParamsContext/DevicesQueryParamsContextProvider";
import useQueryDevices from "../../hooks/useQueryDevices";
import Arrow, { Direction } from "../../icons/Arrow";
import VehicleIcon from "../../icons/VehicleIcon";
import { DevicesSortFilterParamName } from "../../services/devices.service";
import DateTimeSelectFilter from "../Filters/DateTimeSelectFilter";
import { FleetEntry } from "../FleetView/FleetView";
import { onClickRow, queryResultToTableData } from "../FleetView/fleet-utils";
import SortingHeaderContainer from "../SortingHeaderContainer/SortingHeaderContainer";
import Pagination from "../Table/Pagination";
import Table from "../Table/Table";
import CellContainer from "./CellContainer";
import ExpandedRow from "./ExpandedRow";
import styles from "./FleetViewTable.module.scss";
import { DEVICES_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";
const columns: Column<FleetEntry>[] = [
  {
    accessor: "licensePlate",
    Header: (
      <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="vehicleId">
        <FormattedMessage id="LICENSE_PLATE" defaultMessage="License Plate" />
      </SortingHeaderContainer>
    ),
    Cell: (cell: CellProps<FleetEntry>) => {
      return (
        <CellContainer cellProps={cell}>
          <div className={`d-flex align-items-center gap-2`}>
            <Arrow direction={cell.row.isExpanded ? Direction.Up : Direction.Down} color={Color.DARK_GRAY_1} />
            <div className="text-truncate">{cell.value}</div>
          </div>
        </CellContainer>
      );
    },
  },
  {
    accessor: "vehicleType",
    Header: (
      <div className="d-flex justify-content-center">
        <FormattedMessage id="TYPE" defaultMessage="Type" />
      </div>
    ),
    Cell: (cell: CellProps<FleetEntry>) => {
      return (
        <CellContainer cellProps={cell}>
          <div className="d-flex justify-content-center">
            <VehicleIcon name={cell.value} />
          </div>
        </CellContainer>
      );
    },
  },
  {
    id: "driver-id-col",
    accessor: (entry) => entry.lastEvent?.driverId,
    Header: (
      <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="eventDriverid">
        <FormattedMessage id="DRIVER_ID" defaultMessage="Driver ID" />
      </SortingHeaderContainer>
    ),
    Cell: (cell: CellProps<FleetEntry>) => <CellContainer cellProps={cell}>{cell.value}</CellContainer>,
  },
  {
    accessor: "fleetName",
    Header: (
      <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="fleetAssigned">
        <FormattedMessage id="FLEET" defaultMessage="Fleet" />
      </SortingHeaderContainer>
    ),
    Cell: (cell) => {
      return <CellContainer cellProps={cell}>{cell.value}</CellContainer>;
    },
  },
  {
    accessor: "id",
    Header: (
      <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="id">
        <FormattedMessage id="DEVICE_ID" defaultMessage="Device ID" />
      </SortingHeaderContainer>
    ),
    Cell: (cell) => <CellContainer cellProps={cell}>{cell.value}</CellContainer>,
  },
];

interface FleetViewTableProps {
  selectedRow?: number;
  setSelectedRow: (row: number) => void;
  onClickLastEvent: () => void;
  onClickTrips?: () => void;
  onDataChange?: (data: FleetEntry[]) => void;
}

export const FleetViewTable = ({
  selectedRow,
  setSelectedRow,
  onClickLastEvent,
  onClickTrips,
  onDataChange,
}: FleetViewTableProps) => {
  const { queryParams, setPageIndex } = useDevicesQueryParamsContext();
  const { data: resp, isLoading, isFetching, isError } = useQueryDevices(queryResultToTableData, true);

  const { data, pageCount, totalCount } = resp ?? {};

  useEffect(() => {
    if (!data) return;

    onDataChange?.(data);
  }, [data, onDataChange]);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="px-2 d-flex flex-column gap-3 py-2">
        <DateTimeSelectFilter<DevicesSortFilterParamName>
          context={DEVICES_CONTEXT}
          paramName="time"
          width="100%"
          includeInUrl
          urlToParamName="to"
        />
        <Table
          data={data ?? []}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            td: (row) => `${styles.td} ${row.isExpanded ? "border-bottom-0" : ""}`,
          }}
          isLoading={isLoading}
          isError={isError}
          onClickRow={onClickRow}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          renderRowSubComponent={(row, table) => (
            <ExpandedRow row={row} table={table} onClickEventType={onClickLastEvent} onClickTrips={onClickTrips} />
          )}
        />
      </div>
      <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        onChangePageIndex={setPageIndex}
        pageCount={pageCount}
        allDisabled={isFetching}
        totalEntries={totalCount}
      />
    </div>
  );
};

export default FleetViewTable;
