import { QueryFunctionContext } from "@tanstack/react-query";
import { GetDriversParams, GetDriversResult, driversService,GetDriverParams, BaseDriverOptions } from "../services/driver.service";
import { FleetSenseDevice } from "../api/data-contracts";

export type GetDriverDevicesParams = BaseDriverOptions;
export type GetDriverDevicesResult =  {data: FleetSenseDevice[] | undefined,  isLoading: boolean,isError : boolean,isFetching: boolean }
export function driversQuery<selectedT>(
  params: GetDriversParams,
  selector: (queryResult: GetDriversResult) => selectedT,
  enabled: boolean
) {
  return {
    queryKey: ["drivers", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["drivers", GetDriversParams]>) =>
      await driversService.getDrivers(context.queryKey[1]),
    select: selector,
    enabled: enabled,
    retry: false
  };
}

export function driverDevicesQuery( params: GetDriverParams, enabled: boolean) {
  return {
    queryKey: ["driverDevices", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["driverDevices", GetDriverParams]>) =>
      (await driversService.getDriver(context.queryKey[1])).data?.devices,
    staleTime: Infinity,
    enabled: enabled,
  };
}
