import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../constants";
import { useFleetsLayoutContext } from "../../context/FleetsLayoutContext";
import { fleetEntryToMapEvent } from "../../util/events-map.util";
import { MediaDetails } from "../EventsTable/EventsTable";
import FleetViewTable from "../FleetViewTable/FleetViewTable";
import { initialFleetViewQueryParams } from "./fleet-utils";
import { DevicesQueryParamsContextProvider } from "../../context/QueryParamsContext/DevicesQueryParamsContextProvider";
import { useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";

export type FleetEntry = {
  index: number;
  id?: string;
  vehicleType?: string;
  licensePlate?: string;
  fleetName?: string;
  lastEvent?: {
    id?: number;
    driverId?: string;
    tripId?: number;
    time?: string;
    type?: string;
    speed?: number;
    heading?: number;
    mediaArr: MediaDetails[];
    eventAttr1?: string;
    category?: number;
    location: {
      address?: string;
      time?: string;
      lat: number;
      lng: number;
    };
  };
};

function FleetView() {
  const { selected } = useTspFleetContext();

  const { selectedDataIndex, setSelectedDataIndex, setShowEventInfo, setMapEvents, setSelectedDeviceEvent, mapEvents, setTableEvents } =
    useFleetsLayoutContext();

  const dataChangedHandler = useCallback(
    (data: FleetEntry[]) => {
      setMapEvents(fleetEntryToMapEvent(data, selected));
      setTableEvents(fleetEntryToMapEvent(data, selected));
    },
    [setMapEvents, setTableEvents , selected]
  );

  const navigate = useNavigate();

  function navToTrips() {
    navigate(`/${RouteConstants.EVENTS_ON_MAP}/${RouteConstants.TRIPS}`);
  }

  return (
    <DevicesQueryParamsContextProvider initialParams={initialFleetViewQueryParams}>
      <FleetViewTable
        selectedRow={selectedDataIndex}
        setSelectedRow={(index) => {
          setSelectedDataIndex(index);
          setSelectedDeviceEvent(mapEvents?.[index]);
        }}
        onClickLastEvent={() => setShowEventInfo(true)}
        onClickTrips={navToTrips}
        onDataChange={dataChangedHandler}
      />
    </DevicesQueryParamsContextProvider>
  );
}

export default FleetView;
