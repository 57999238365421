import { QueryParamsContextProvider, useQueryParamsContext } from "./QueryParamsContext";
import { PropsWithChildren } from "react";
import { Constants } from "../../constants";
import { DriversSortFilterParamName, GetDriversParams } from "../../services/driver.service";
import { DRIVERS_CONTEXT } from "./queryParamContextObjects";
import { dateUtils } from "../../util/date.util";


export function DriversQueryParamsContextProvider({ children }: PropsWithChildren) {
  const initialParams: GetDriversParams = {
    filters: [{
      paramName: "eventMessageTime",
      operator: ">=",
      values: [dateUtils.getFormattedDate(new Date(), "YYYY-MM-DD")],
      urlParamName: "from",
    }],
    sorts: [],
    paging: { pageIndex: 1, pageSize: Constants.DEFAULT_PAGESIZE },
  };

  return <QueryParamsContextProvider context={DRIVERS_CONTEXT} initialParams={initialParams}>{children}</QueryParamsContextProvider>;
}

export const useDriversQueryParamsContext = () =>
  useQueryParamsContext<DriversSortFilterParamName, DriversSortFilterParamName>(DRIVERS_CONTEXT);
