import { QueryFunctionContext } from "@tanstack/react-query";
import { GetUsersParams, GetUsersResult, userService } from "../services/user.service";

export function usersQuery<selectedT>(
  params: GetUsersParams,
  selector: (queryResult: GetUsersResult) => selectedT,
  enabled: boolean
) {
  return {
    queryKey: ["users", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["users", GetUsersParams]>) =>
      await userService.getUsers(context.queryKey[1]),
    select: selector,
    enabled: enabled,
    retry: false
  };
}

export function userQuery(email: string, enabled: boolean) {
  return {
    queryKey: ["user", email] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["user", string]>) =>
      await userService.getUser(context.queryKey[1]),
    staleTime: Infinity,
    enabled: enabled,
  };
}
