import {
  DevicesFilterParam,
  GetAllDevicesParams,
  GetDeviceParams,
  GetDevicesResult,
} from "../services/devices.service";
import { useQuery } from "@tanstack/react-query";
import { deviceQuery, devicesQuery } from "../queries/devices.query";
import { useDevicesQueryParamsContext } from "../context/QueryParamsContext/DevicesQueryParamsContextProvider";
import { useEffect } from "react";
import useFilterByTspFleetContext from "./useFilterByTspFleetContext";
import { useTspFleetContext } from "../context/TspFleetContext/TspFleetContext";
import { getIsFleetRole } from "../util/roles.util";
import { useAuthContext } from "../context/AuthContext";
import { getInitTspFleetFilter } from "../util/filtering.util";
import { FleetSenseDevice } from "../api/data-contracts";
import { FilterParam } from "../models/requests/shared.request";

function useQueryDevices<TResult extends { pageCount?: number }>(
  selector: (queryResult: GetDevicesResult) => TResult,
  includeInstallationReport: boolean,
  enabled: boolean = true
) {
  const { queryParams, setPageIndex, upsertFilter, removeFilter } = useDevicesQueryParamsContext();
  const { isApplied: isTspFleetFilterApplied, isRequired: isTspFleetFilterRequired } = useFilterByTspFleetContext(
    upsertFilter,
    removeFilter,
    queryParams.filters
  );
  const showData = !isTspFleetFilterRequired || isTspFleetFilterApplied;

  const { data, isLoading, isError, isFetching } = useQuery(
    devicesQuery({ ...queryParams, includeInstallationReport }, selector, showData)
  );

  useEffect(() => {
    const pageCount = data?.pageCount;
    if (!isFetching && pageCount && queryParams.paging.pageIndex > pageCount) {
      setPageIndex(pageCount);
    }
  }, [data, isFetching, queryParams, setPageIndex]);

  return {
    data: showData ? data : undefined,
    isLoading: isLoading && isFetching,
    isError,
    isFetching,
  };
}

export function useQueryDeviceFilteredByDeviceIdOrVehicle<TResult extends { pageCount?: number }>(
  filter: string | undefined,
  driverDevices: FleetSenseDevice[] | undefined,
  fleet:  {tspId: number, fleetId?: number} | undefined,
  selector: (queryResult: GetDevicesResult) => TResult,
  enabled: boolean = true
) {
  
  const { queryParams, setPageIndex } = useDevicesQueryParamsContext();
  const { paging, sorts } = queryParams;
  
  const filterParam: GetAllDevicesParams = {
    paging: paging,
    sorts: sorts,
    filters: [
      {
        paramName: "(id|vehicleId)",
        operator: "@=",
        values: filter ? [filter] : [],
      },
      {
        paramName: "tspId",
        operator: "==",
        values: fleet?.fleetId && fleet.tspId ? [fleet.tspId.toString()] : [],
      },
      {
        paramName: "fleetId",
        operator: "==",
        values: fleet?.fleetId ? [fleet.fleetId.toString()]: [],
      },
    ],
    includeInstallationReport: false,
  };
  let driverDevicesFilters = driverDevices?.map((dev) => ({ paramName: "id", operator: "!=", values:  [dev.id!] } as FilterParam<string>));
  if (driverDevicesFilters  && driverDevicesFilters.length > 0) {
    filterParam.filters = [...filterParam.filters,  ...driverDevicesFilters ];
  }
  const { data, isLoading, isError, isFetching } = useQuery(devicesQuery(filterParam, selector, enabled && !!fleet?.fleetId && !!fleet?.tspId));

  useEffect(() => {
    const pageCount = data?.pageCount;
    if (!isFetching && pageCount && queryParams.paging.pageIndex > pageCount) {
      setPageIndex(pageCount);
    }
  }, [data, isFetching, queryParams, setPageIndex]);

  return {
    data: data,
    isLoading: isLoading && isFetching,
    isError,
    isFetching,
  };
}

export function useQueryAllDevices<TResult extends { pageCount?: number }>(
  filters: DevicesFilterParam[],
  selector: (queryResult: GetDevicesResult) => TResult,
  needed: boolean,
  enable: boolean = false,
  geocoding : boolean = false
) {
  const { activeTsp, activeFleets } = useTspFleetContext();
  const role = useAuthContext().loggedUser?.role;
  const isFleetRole = getIsFleetRole(role);
  const isAnyFleetActive = !!activeFleets.length;
  const showData = isFleetRole || isAnyFleetActive || enable;

  const tspFleetFilter = getInitTspFleetFilter(role, activeTsp, activeFleets);

  const isTspFilterExist = filters.some((filter) => filter.paramName === tspFleetFilter?.paramName);

  const { data, isLoading, isError } = useQuery(
    devicesQuery(
      {
        filters: isTspFilterExist ? filters : filters.concat(tspFleetFilter ? [tspFleetFilter] : []),
        sorts: [],
        includeInstallationReport: false,
        geocoding: geocoding
      },
      selector,
      enable
    )
  );
  var dataRet = needed && showData ? data : undefined;
  return { dataAll: dataRet, isLoading, isError };
}

export function useGetDevice(param: GetDeviceParams, enable: boolean = true) {
  const { data, isLoading, isError, isFetching } = useQuery(deviceQuery(param, enable));
  return { data, isLoading, isError, isFetching };
}

export default useQueryDevices;
