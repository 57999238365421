import { useCallback, useEffect, useMemo, useState } from "react";
import TreeView, { INode, NodeId, flattenTree } from "react-accessible-treeview";
import { Fleet, Role } from "../../api/data-contracts";
import DefaultFleetIcon from "../../assets/icons/FleetWhiteBg.svg";
import ActiveTspCircleIcon from "../../assets/icons/SideTreeActiveTsp.svg";
import SelectedEllipseIcon from "../../assets/icons/SideTreeSelected.svg";
import DefaultTspIcon from "../../assets/icons/TspWhiteBg.svg";
import CipiaIcon from "../../assets/mocks/CipiaIcon.svg";
import { Color } from "../../constants";
import { useAuthContext } from "../../context/AuthContext";
import { SelectedType, Tsp, useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import { isCipia, isTsp } from "../../context/TspFleetContext/tsp-fleet-context.util";
import Arrow, { Direction } from "../../icons/Arrow";
import { addBase64Prefix } from "../../util/shared.util";
import CircleButton from "../Buttons/CircleButton/CircleButton";
import CheckBoxIcon from "./CheckBoxIcon";
import EditDeleteTspFleetModals, { Operation, OperationData } from "./EditDeleteTspFleetModals";
import RightClickMenu, { RightClickMenuParamsType } from "./RightClickMenu";
import styles from "./SideBar.module.scss";
import SideBarControl from "./SideBarControl";
import "./TreeStyles.css";

interface ITreeNode {
  id?: NodeId;
  name: string;
  children?: ITreeNode[];
}

type NodeType = "TSP" | "FLEET" | "CIPIA";

type SideBarType = {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  showExpander: boolean;
};

function SideBar({ opened, setOpened, showExpander }: SideBarType) {
  const [operation, setOperation] = useState<Operation>(null);
  const [operationData, setOperationData] = useState<OperationData>();
  const role = useAuthContext().loggedUser?.role;
  const { allTsps, activeTsp, activeFleets, toggleFleet, toggleTsp, toggleCipia, getTspById, getFleetById, selected } =
    useTspFleetContext();
  const [rightClickMenuShow, setRightClickMenuShow] = useState<RightClickMenuParamsType & { data?: Tsp | Fleet }>({
    show: false,
    isTsp: false,
    location: { x: 0, y: 0 },
  });

  const data: INode[] = useMemo(() => {
    const treeTsps: ITreeNode[] = allTsps.map(({ id, companyFriendlyName, children }) => ({
      id: tspIdToNodeId(id),
      name: companyFriendlyName!,
      children: children.map((fleet) => ({ id: fleetIdToNodeId(fleet.id), name: fleet.companyFriendlyName || "" })),
    }));
    if (role === Role.CipiaAdministrator) {
      return flattenTree({ id: "root", name: "root", children: [{ name: "Cipia", id: CIPIA_ID, children: treeTsps }] });
    }
    return flattenTree({ id: "root", name: "root", children: treeTsps });
  }, [allTsps, role]);

  const selectedIds: NodeId[] = useMemo(() => {
    let ids: string[] = [];
    if (role === Role.CipiaAdministrator) ids.push(CIPIA_ID);
    if (activeTsp) {
      ids.push(tspIdToNodeId(activeTsp.id));
      ids = ids.concat(activeFleets.map((fleet) => fleetIdToNodeId(fleet.id)));
    }
    return ids;
  }, [activeTsp, activeFleets, role]);

  const [expandedIds, setExpandedIds] = useState<NodeId[]>([]);

  useEffect(() => {
    setExpandedIds((prev) =>
      Array.from(
        new Set([...prev, ...selectedIds.filter((id) => data.find((node) => node.id === id)?.children.length)])
      )
    );
  }, [data, selectedIds]);

  const onToggleNode = useCallback(
    (nodeId: NodeId, expand: boolean) =>
      setExpandedIds((prev) => (expand ? Array.from(new Set([...prev, nodeId])) : prev.filter((id) => id !== nodeId))),
    []
  );

  return (
    <div className={styles["side-bar"]}>
      <div className={styles["side-bar-list"]}>
        <span style={{ display: opened ? "block" : "none" }}>
        <SideBarControl />

        <TreeView
          className="py-3 px-1"
          data={data}
          aria-label="Checkbox-tree"
          multiSelect
          selectedIds={selectedIds}
          expandedIds={expandedIds}
          onExpand={({ element: { id }, isExpanded }) => onToggleNode(id, isExpanded)}
          nodeRenderer={({
            element,
            isExpanded,
            isSelected: isChecked,
            isDisabled,
            getNodeProps,
            level,
            handleExpand,
          }) => {
            const nodeId = element.id.toString();
            const nodeType: NodeType = getNodeType(nodeId);
            const data =
              nodeType === "CIPIA"
                ? undefined
                : nodeType === "TSP"
                ? getTspById(nodeIdToTspId(nodeId))
                : getFleetById(nodeIdToFleetId(nodeId));
            const isSelected = isNodeSelected(selected, nodeType, nodeId);

            return (
              <div
                {...getNodeProps({ onClick: handleExpand })}
                className={`${styles.row} d-flex align-items-center`}
                style={{
                  opacity: isDisabled ? 0.5 : 1,
                  cursor: "pointer",
                  border: isSelected ? `2px solid ${Color.LIGHT_BLUE}` : "2px solid rgb(0, 0, 0, 0)",
                }}
                onContextMenu={(e: any) => {
                  if (e.type === "contextmenu" && nodeType !== "CIPIA") {
                    if (nodeType === "FLEET") {
                      toggleFleet(data?.id!, true);
                    } else {
                      toggleTsp(data?.id!);
                    }
                    setRightClickMenuShow({
                      show: true,
                      isTsp: nodeType === "TSP",
                      location: { x: e.clientX, y: e.clientY },
                      data,
                    });
                  }
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <div className="d-flex align-items-center">
                  <img
                    style={{ opacity: isSelected || (nodeType === "TSP" && isChecked) ? 100 : 0, marginTop: "1.5px" }}
                    src={isSelected ? SelectedEllipseIcon : ActiveTspCircleIcon}
                    alt="ico"
                  />
                </div>
                <span
                  className="text-truncate d-flex align-items-center"
                  style={{
                    marginLeft: 24 * (level - 1),
                  }}
                >
                  <Arrow
                    direction={isExpanded ? Direction.Down : Direction.Right}
                    color="#A8CFFF"
                    width="18"
                    visibility={nodeType === "FLEET" ? "hidden" : "visible"}
                  />

                  <div
                    className="d-flex align-items-center text-truncate"
                    onClick={(e) => {
                      if (nodeType === "CIPIA") {
                        toggleCipia();
                      } else if (nodeType === "TSP") {
                        toggleTsp(nodeIdToTspId(nodeId));
                      } else {
                        toggleFleet(nodeIdToFleetId(nodeId), true);
                      }
                      e.stopPropagation();
                    }}
                  >
                    <CheckBoxIcon
                      className={styles["checkbox-icon"]}
                      onClick={(e: any) => {
                        if (nodeType === "CIPIA") {
                          toggleCipia();
                        } else if (nodeType === "TSP") {
                          toggleTsp(nodeIdToTspId(nodeId));
                        } else {
                          toggleFleet(nodeIdToFleetId(nodeId), false);
                        }
                        e.stopPropagation();
                      }}
                      variant={nodeType !== "FLEET" ? (isChecked ? "rb_sel" : "rb_none") : isChecked ? "all" : "none"}
                    />
                    <img
                      style={{ width: "26px" }}
                      src={
                        data?.logo
                          ? addBase64Prefix(data.logo || "") //TODO it properly in service?
                          : nodeType === "CIPIA"
                          ? CipiaIcon
                          : nodeType === "TSP"
                          ? DefaultTspIcon
                          : DefaultFleetIcon
                      }
                      alt="ico"
                    />
                    <span
                      className={`${styles.name} text-nowrap text-truncate`}
                      style={{
                        fontWeight: isSelected ? "700" : "400",
                        color: isChecked ? Color.WHITE : Color.LIGHT_GRAY_5,
                      }}
                    >
                      {element.name}
                    </span>
                  </div>
                </span>
              </div>
            );
          }}
        />
        </span>
      </div>
      {showExpander && <CircleButton opened={opened} onClick={() => setOpened(!opened)} top="2px" />}
      <RightClickMenu
        {...rightClickMenuShow}
        onClickDelete={() => {
          rightClickMenuShow.isTsp ? setOperation("DELETE_TSP") : setOperation("DELETE_FLEET");
          setOperationData(rightClickMenuShow.data);
        }}
        onClickEdit={() => {
          rightClickMenuShow.isTsp ? setOperation("EDIT_TSP") : setOperation("EDIT_FLEET");
          setOperationData(rightClickMenuShow.data);
        }}
        setRightClickMenuShow={setRightClickMenuShow}
      />
      <EditDeleteTspFleetModals
        operation={operation}
        data={operationData!}
        onFinish={(success) => {
          setOperation(null);
          setOperationData(undefined);
        }}
      />
    </div>
  );
}

export default SideBar;

function getNodeType(nodeId: string): NodeType {
  if (nodeId === CIPIA_ID) return "CIPIA";
  if (nodeId.startsWith("tsp")) return "TSP";
  return "FLEET";
}

const CIPIA_ID = "CIPIA_ID";
function tspIdToNodeId(tspId?: number): string {
  return `tsp${tspId}`;
}
function fleetIdToNodeId(fleetId?: number): string {
  return `fleet${fleetId}`;
}
function nodeIdToTspId(nodeId: string): number {
  return Number(nodeId.toString().replace("tsp", ""));
}
function nodeIdToFleetId(nodeId: string): number {
  return Number(nodeId.toString().replace("fleet", ""));
}

function isNodeSelected(ctxSelected: SelectedType | undefined, nodeType: NodeType, nodeId: string) {
  if (ctxSelected) {
    if (isCipia(ctxSelected)) {
      if (nodeType === "CIPIA") {
        return true;
      }
    } else if (isTsp(ctxSelected)) {
      if (nodeType === "TSP" && ctxSelected.id === nodeIdToTspId(nodeId)) {
        return true;
      }
    } else {
      if (nodeType === "FLEET" && ctxSelected.id === nodeIdToFleetId(nodeId)) {
        return true;
      }
    }
  }
  return false;
}
