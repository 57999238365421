import Table from "../Table/Table";
import { Row } from "react-table";
import styles from "./DriversTable.module.scss";
import Pagination from "../Table/Pagination";
import { Role } from "../../api/data-contracts";
import { useEffect, useMemo, useReducer, useState } from "react";
import { EMPTY_ARR } from "../../util/shared.util";
import { modalInitialState, modalReducer } from "../../util/reducers/modal-reducer.util";
import DeleteDriverModal from "./modals/DeleteDriverModal";
import DriverCardModal from "./modals/DriverCardModal";
import { SelectedType, useTspFleetContext } from "../../context/TspFleetContext/TspFleetContext";
import { useAuthContext } from "../../context/AuthContext";
import {
  DriversQueryParamsContextProvider,
  useDriversQueryParamsContext,
} from "../../context/QueryParamsContext/DriversQueryParamsContextProvider";
import DriversTableController from "./DriversTableController";
import useQueryDrivers from "../../hooks/useQueryDrivers";
import { generateDriversColumns, onClickRow, queryResultToTableData } from "./drivers-table.util";
import { isFleet } from "../../context/TspFleetContext/tsp-fleet-context.util";
import ExpandedRow from "./ExpandedRow";
import { useNavigate } from "react-router-dom";

export interface DriverTableEntry {
  id?: number;
  name?: string;
  driverId: string;
  image?: string;
  vector?: string;
  fleet?: string;
  fleetId: number;
  tspId: number;
  lastEventType?: string;
  eventMessageTime?: string;
  lastTripId?: number | null;
}
type DriverModalName = "CREATE" | "DRIVER_CARD" | "DELETE_DRIVER";
export interface DriverModalData {
  drivers?: DriverTableEntry[];
  tspTreeSelected?: SelectedType;
}

function DriversTable() {
  const navigate = useNavigate();
  const { queryParams, setPageIndex } = useDriversQueryParamsContext();
  const { data, isError, isLoading, isFetching } = useQueryDrivers(queryResultToTableData);

  const [selectedRows, setSelectedRows] = useState<Row<DriverTableEntry>[]>([]);
  const selectedDrivers = useMemo(() => selectedRows.map((row) => row.original), [selectedRows]);

  const [modalState, dispatch] = useReducer(modalReducer<DriverModalName, DriverModalData>, modalInitialState);
  const columns = useMemo(() => generateDriversColumns(), []);
  const { loggedUser } = useAuthContext();

  const { selected: tspTreeSelected } = useTspFleetContext();
  const isTsp = loggedUser?.role === Role.SpManager || loggedUser?.role === Role.SpUser;
  const isFleetUser = loggedUser?.role === Role.FleetUser || loggedUser?.role === Role.FleetManager;

  useEffect(() => {
    if (isTsp) {
      navigate("/");
    }
  }, [loggedUser, navigate, isTsp]);

  return (
    <div className="h-100 d-flex flex-column">
      <div className="px-3">
          <DriversTableController
            driversCount={data?.totalCount}
            disable={{
              add: !!selectedDrivers.length || (!isFleetUser && (!tspTreeSelected || !isFleet(tspTreeSelected))),
              delete: selectedDrivers.length === 0,
              edit: selectedDrivers.length !== 1,
            }}
            onClickEdit={() =>
              dispatch({
                type: "SET_SHOW",
                payload: { modalName: "DRIVER_CARD", data: { drivers: selectedDrivers, tspTreeSelected } },
              })
            }
            onClickAdd={() => {
              dispatch({
                type: "SET_SHOW",
                payload: { modalName: "CREATE", data: { tspTreeSelected } },
              });
            }}
            onClickDelete={() =>
              dispatch({
                type: "SET_SHOW",
                payload: { modalName: "DELETE_DRIVER", data: { drivers: selectedDrivers } },
              })
            }
          />
        <Table<DriverTableEntry>
          data={data?.data || EMPTY_ARR}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            tr: (row) => `${row.isSelected || row.isExpanded ? "selected-row-bg" : ""} ${styles.tr}`,
            td: (row) => `${styles.td} ${row.isExpanded ? "border-bottom-0" : ""}`,
          }}
          isLoading={isLoading}
          isError={isError}
          onChangeSelectedRows={setSelectedRows}
          onClickRow={onClickRow}
          renderRowSubComponent={(row, table) => <ExpandedRow row={row} table={table} />}
        />
      </div>
      <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        onChangePageIndex={setPageIndex}
        pageCount={data?.pageCount}
        allDisabled={isFetching}
        totalEntries={data?.totalCount}
      />
      {(modalState.shownModal === "CREATE" || modalState.shownModal === "DRIVER_CARD") && (
        <DriversQueryParamsContextProvider>
          <DriverCardModal
            driverInitialData={selectedDrivers[0]}
            show={modalState.shownModal === "CREATE" || modalState.shownModal === "DRIVER_CARD"}
            onClickClose={() => dispatch({ type: "CLEAR" })}
            isEdit={modalState.shownModal === "DRIVER_CARD"}
          />
        </DriversQueryParamsContextProvider>
      )}
      {modalState.shownModal === "DELETE_DRIVER" && (
        <DeleteDriverModal
          show={modalState.shownModal === "DELETE_DRIVER"}
          onClickNo={() => dispatch({ type: "CLEAR" })}
          onClickYes={() => dispatch({ type: "CLEAR" })}
          driversData={modalState.data?.drivers!}
        />
      )}
    </div>
  );
}

export default DriversTable;
