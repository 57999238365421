import { FormattedMessage } from "react-intl";
import { CellProps, Column } from "react-table";
import { MediaSource, MediaType } from "../../api/data-contracts";
import { Color } from "../../constants";
import { useEventsQueryParamsContext } from "../../context/QueryParamsContext/EventsQueryParamsContextProvider";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";
import { useFormattedSpeed } from "../../hooks/useFormattedSpeed";
import useQueryEvents from "../../hooks/useQueryEvents";
import Arrow, { Direction } from "../../icons/Arrow";
import EventIcon from "../../icons/EventIcon";
import Photo from "../../icons/Photo";
import VehicleIcon from "../../icons/VehicleIcon";
import Video from "../../icons/Video";
import { EventsSortAndFilterParamNames } from "../../services/events.service";
import { getEventMetadata } from "../../util/events.util";
import SortingHeaderContainer from "../SortingHeaderContainer/SortingHeaderContainer";
import Pagination from "../Table/Pagination";
import Table from "../Table/Table";
import styles from "./EventsTable.module.scss";
import EventsTableControl from "./EventsTableControl";
import ExpandedRow from "./ExpandedRow";
import { onClickRow, queryResultToTableData } from "./events-util";
import { EVENTS_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";

export interface MediaDetails {
  type: MediaType;
  source: MediaSource;
  index: number;
}

export interface EventsTableEntry {
  vehicleType?: string;
  eventAttr1?: string;
  vehicleId?: string;
  driverId?: string;
  type?: string;
  mediaArr: MediaDetails[];
  deviceId?: string;
  messageId?: number;
  dateTime?: string;
  speed?: number;
  tripId?: number;
  locationAddr?: string;
}

const columns: Column<EventsTableEntry>[] = [
  {
    accessor: "vehicleId",
    Header: (
      <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="licensePlate">
        <FormattedMessage id="LICENSE_PLATE" defaultMessage="License Plate" />
      </SortingHeaderContainer>
    ),
    Cell: (cell) => (
      <div className={`d-flex align-items-center gap-2`}>
        <Arrow direction={cell.row.isExpanded ? Direction.Up : Direction.Down} color={Color.DARK_GRAY_1} />
        <div>{cell.value}</div>
      </div>
    ),
  },
  {
    accessor: "vehicleType",
    Header: (
      <div className="d-flex align-items-center justify-content-center">
        <FormattedMessage id="TYPE" defaultMessage="Type" />
      </div>
    ),
    Cell: (cell) => (
      <div className="d-flex align-items-center justify-content-center">
        <VehicleIcon name={cell.value} />
      </div>
    ),
  },
  {
    accessor: "driverId",
    Header: (
      <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="driverId">
        <FormattedMessage id="DRIVER_ID" defaultMessage="Driver ID" />
      </SortingHeaderContainer>
    ),
  },
  {
    accessor: "tripId",
    Header: (
      <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="tripId">
        <FormattedMessage id="TRIP_ID" defaultMessage="Trip ID" />
      </SortingHeaderContainer>
    ),
  },
  {
    accessor: "type",
    Header: (
      <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="eventType">
        <FormattedMessage id="EVENT_TYPE" defaultMessage="Event Type" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value, row: { original } }: CellProps<EventsTableEntry>) => (
      <div className="d-flex align-items-center gap-2">
        <EventIcon serverName={value} />
        <div>
          {getEventMetadata(value)?.displayName}
          <>
            <br />
            {
              original.eventAttr1 && original.eventAttr1
            }
          </>
        </div>
      </div>
    ),
  },
  {
    accessor: "mediaArr",
    Header: () => <FormattedMessage id="EVENT_RECORDS" defaultMessage="Event Records" />,
    Cell: ({ value }: CellProps<EventsTableEntry, MediaDetails[]>) => {
      const videos = [];
      const photos = [];
      for (const media of value) {
        if (media.type === MediaType.Video) {
          videos.push(<Video color={Color.CIPIA_BLUE} key={`${media.type}${media.source}${media.index}`} />);
        } else {
          photos.push(<Photo color={Color.CIPIA_BLUE} key={`${media.type}${media.source}${media.index}`} />);
        }
      }
      return (
        <div className="d-flex align-items-center gap-2">
          {videos}
          {photos}
        </div>
      );
    },
  },
  {
    accessor: "deviceId",
    Header: (
      <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="deviceID">
        <FormattedMessage id="DEVICE_ID" defaultMessage="Device ID" />
      </SortingHeaderContainer>
    ),
  },
  {
    accessor: "dateTime",
    Header: (
      <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="eventDateTime">
        <FormattedMessage id="EVENT_DATE_TIME" defaultMessage="Event Date and Time" />
      </SortingHeaderContainer>
    ),
    Cell: ({ value }) => {
      const { getFormattedLocalDate } = useFormattedLocalDate();
      return !value ? null : <div>{getFormattedLocalDate(value)}</div>;
    },
  },
  {
    accessor: "speed",
    Header: () => {
      const { isImperial } = useFormattedSpeed();
      return (
        <div className="d-flex justify-content-center">
          <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="speed">
            {isImperial ? (
              <FormattedMessage id="SPEED_MPH" defaultMessage="Speed (mph)" />
            ) : (
              <FormattedMessage id="SPEED_KMH" defaultMessage="Speed (km/h)" />
            )}
          </SortingHeaderContainer>
        </div>
      );
    },
    Cell: ({ value }) => {
      const { getSpeed } = useFormattedSpeed();
      return value === undefined ? null : <div className="d-flex justify-content-center">{getSpeed(value)}</div>;
    },
  },
  {
    accessor: "locationAddr",
    Header: <FormattedMessage id="LOCATION" defaultMessage="Location" />,
  },
];

export const EventsTable = () => {

  const { data, isLoading, isError, isFetching } = useQueryEvents(queryResultToTableData);
  const { queryParams, setPageIndex } = useEventsQueryParamsContext();
  return (
    <div className="h-100 d-flex flex-column">
      <div className="px-3">
        <EventsTableControl />
        <Table
          data={data?.data || []}
          columns={columns}
          classes={{
            wrapper: () => styles["table-wrapper"],
            table: () => styles.table,
            thead: () => styles.thead,
            th: () => styles.th,
            tr: (row) => `${row.isSelected ? "selected-row-bg" : ""} ${styles.tr}`,
            td: (row) => `${styles.td} ${row.isExpanded ? "border-bottom-0" : ""}`,
          }}
          isLoading={isLoading}
          isError={isError}
          onClickRow={onClickRow}
          renderRowSubComponent={(row, table) => <ExpandedRow row={row} table={table} />}
        />
      </div>
      <Pagination
        className="mt-auto"
        debouncedPageIndex={queryParams.paging.pageIndex}
        onChangePageIndex={setPageIndex}
        pageCount={data?.pageCount}
        allDisabled={isFetching}
        totalEntries={data?.totalCount}
      />
    </div>
  );
};

export default EventsTable;
