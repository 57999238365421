import { QueryFunctionContext } from "@tanstack/react-query";

import { GetAllDevicesParams, GetDeviceParams, GetDevicesResult,  devicesService } from "../services/devices.service";

export function devicesQuery<TResult>(
  params: GetAllDevicesParams,
  selector: (queryResult: GetDevicesResult) => TResult,
  enabled: boolean
) {
  return {
    queryKey: ["devices", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["devices", GetAllDevicesParams]>) =>
      await devicesService.getDevices(context.queryKey[1]),
    select: selector,
    keepPreviousData: true,
    enabled: enabled,
    retry: false
  };
}


export function deviceQuery(
  params: GetDeviceParams,
  enabled: boolean
) {
  return {
    queryKey: ["device", params] as const,
    queryFn: async (context: QueryFunctionContext<readonly ["device", GetDeviceParams]>) =>
      await devicesService.getDevice(context.queryKey[1]),
    enabled: enabled,
  };
}



