import MultiSelectFilter from "../Filters/MultiSelectFilter";
import { FormattedMessage, useIntl } from "react-intl";
import { EventsSortAndFilterParamNames, eventsService } from "../../services/events.service";
import DateTimeSelectFilter from "../Filters/DateTimeSelectFilter";
import { InputUi } from "../../constants";
import { useQueryAllDevices } from "../../hooks/useQueryDevices";
import { useEffect, useMemo, useRef, useState } from "react";
import { EMPTY_ARR, identity } from "../../util/shared.util";
import ActionButton from "../Buttons/ActionButton/ActionButton";
import { useEventsQueryParamsContext } from "../../context/QueryParamsContext/EventsQueryParamsContextProvider";
import { getEventMetadata, getEventsCheckboxTree } from "../../util/events.util";
import { CSVLink } from "react-csv";
import ExpandingTreeFilter from "../Filters/ExpandingTreeFilter";
import TextInputFilter from "../Filters/TextInputFilter";
import { EventMessage } from "../../api/data-contracts";
import { eventsSchema } from "./EventExportSchema";
import { EVENTS_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";

const eventOptionsTree = getEventsCheckboxTree();

const EventsTableControl = () => {
  const { formatMessage } = useIntl();
  const { queryParams, clearFilters } = useEventsQueryParamsContext();
  const [exportData, setExportData] = useState<EventMessage[]>([]);
  const [exportDataReady, setExportDataReady] = useState(false);

  const {
    dataAll,
    isLoading: isLoadingDevices,
    isError: isErrorDevices,
  } = useQueryAllDevices([], identity, true, true);

  const [deviceIdOptions, licensePlateOptions] = useMemo(() => {
    if (isLoadingDevices || isErrorDevices) return [EMPTY_ARR, EMPTY_ARR];
    const deviceIdOptions = [];
    const licensePlateOptions = [];
    for (const device of dataAll?.data || []) {
      const deviceId = device.id;
      const licensePlate = device.vehicleId;
      if (deviceId) deviceIdOptions.push({ value: deviceId, label: deviceId });
      if (licensePlate) licensePlateOptions.push({ value: licensePlate, label: licensePlate });
    }
    return [deviceIdOptions, licensePlateOptions];
  }, [dataAll, isLoadingDevices, isErrorDevices]);

  const allString = formatMessage({
    id: "ALL",
    defaultMessage: "All",
  });

  const csvLink = useRef<any>(null);

  const doExport = async () => {
    var events = await eventsService.getEvents({ ...queryParams, paging: undefined, geocoding: undefined });
    if (events.data) {
      const formattedData = events.data.map((dataItem) => {
        let street = dataItem.lastKnownStreetAddress;
        if (street) {
          street = street.replace('"', '""');
          street = '"' + street + '"';
        }
        return {
          ...dataItem,
          lastKnownStreetAddress: street,
          speed: dataItem.speed ? Math.round(dataItem.speed * 3.6) : dataItem.speed,
        };
      });
      setExportData(formattedData);
      setExportDataReady(true);
    }
  };

  useEffect(() => {
    if (exportDataReady) {
      csvLink.current.link.click();
      setExportDataReady(false);
    }
  }, [exportData, exportDataReady]);

  return (
    <div className="d-flex gap-2 align-items-end py-2 mb-2 flex-wrap">
      <MultiSelectFilter<EventsSortAndFilterParamNames>
        context={EVENTS_CONTEXT}
        paramName="licensePlate"
        operator="=="
        placeHolder={allString}
        inputLabel={formatMessage({
          id: "LICENSE_PLATE",
          defaultMessage: "License Plate",
        })}
        options={licensePlateOptions}
        width={InputUi.MULTI_STD_WIDTH}
        isLoading={isLoadingDevices}
        includeInUrl
      />
      <MultiSelectFilter<EventsSortAndFilterParamNames>
        context={EVENTS_CONTEXT}
        paramName="deviceID"
        operator="=="
        options={deviceIdOptions}
        isLoading={isLoadingDevices}
        placeHolder={allString}
        width={InputUi.MULTI_STD_WIDTH}
        inputLabel={formatMessage({
          id: "DEVICE_ID",
          defaultMessage: "Device ID",
        })}
        includeInUrl
      />
      <TextInputFilter<EventsSortAndFilterParamNames>
        context={EVENTS_CONTEXT}
        paramName="driverId"
        operator="=="
        placeHolder={allString}
        inputLabel={formatMessage({
          id: "DRIVER_ID",
          defaultMessage: "Driver ID",
        })}
        style={{ width: InputUi.SINGLE_STD_WIDTH }}
        includeInUrl
      />
      <TextInputFilter<EventsSortAndFilterParamNames>
        context={EVENTS_CONTEXT}
        paramName="tripId"
        operator="=="
        placeHolder={allString}
        inputLabel={formatMessage({
          id: "TRIP_ID",
          defaultMessage: "Trip ID",
        })}
        style={{ width: InputUi.SMALL_STD_WIDTH }}
        isNumeric
        includeInUrl
      />

      <ExpandingTreeFilter<EventsSortAndFilterParamNames>
        context={EVENTS_CONTEXT}
        nodes={eventOptionsTree}
        operator="=="
        paramName="eventType"
        placeHolder={allString}
        inputLabel={formatMessage({
          id: "EVENT_TYPE",
          defaultMessage: "Event Type",
        })}
        width={InputUi.SINGLE_STD_WIDTH}
        getLabel={(value) => getEventMetadata(value)?.displayName || value}
        includeInUrl
      />

      <DateTimeSelectFilter
        context={EVENTS_CONTEXT}
        paramName="eventDateTime"
        width={InputUi.SINGLE_STD_WIDTH}
        includeInUrl
        urlFromParamName="from"
        urlToParamName="to"
      />
      <div className="ms-auto d-flex gap-2">
        <ActionButton
          className="action-btn position-relative"
          onClick={() =>
            clearFilters([
              { paramName: "tspId", operator: "==" },
              { paramName: "fleetId", operator: "==" },
              { paramName: "status", operator: "==" },
            ])
          }
        >
          <FormattedMessage id="CLEAR_FILTER" defaultMessage="Clear Filter" />
        </ActionButton>
        <ActionButton onClick={doExport} className="action-btn position-relative">
          <FormattedMessage id="EXPORT" defaultMessage="Export" />
        </ActionButton>

        <CSVLink
          ref={csvLink}
          data={exportData}
          enclosingCharacter={""}
          headers={eventsSchema}
          filename={`events.csv`}
        ></CSVLink>
      </div>
    </div>
  );
};

export default EventsTableControl;
