import { CellProps, Column, Row, TableInstance } from "react-table";
import { DevicesSortFilterParamName, GetDevicesResult } from "../../services/devices.service";
import { VehicleEntry } from "./VehiclesTable";
import { FormattedMessage } from "react-intl";
import PhotoWithOverlay from "./PhotoOverlay";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as UnpairIcon } from "../../assets/icons/Unpair.svg";
import { ReactComponent as JsonIcon } from "../../assets/icons/JSON.svg";
import VehiclePhotoPlaceholder from "../../assets/VehiclePhotoPlaceholder.svg";
import { Color, RouteConstants } from "../../constants";
import { Link } from "react-router-dom";
import StatusIcon from "../../icons/StatusIcon";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import ActionButton from "../Buttons/ActionButton/ActionButton";
import VehicleIcon from "../../icons/VehicleIcon";
import { useFormattedLocalDate } from "../../hooks/useFormattedLocalDate";
import { addBase64Prefix, downloadJsonFile } from "../../util/shared.util";
import { Role } from "../../api/data-contracts";
import { filterForEventsOfLicensePlate } from "../FleetView/fleet-utils";
import SortingHeaderContainer from "../SortingHeaderContainer/SortingHeaderContainer";
import { getStatus } from "../../util/device-status.util";
import { DEVICES_CONTEXT } from "../../context/QueryParamsContext/queryParamContextObjects";

export function devicesToVehicles(devices: GetDevicesResult) {
  return {
    pageCount: devices.pageCount,
    totalCount: devices.totalCount,
    data: devices.data
      ?.filter((device) => getStatus(device) !== "UNPAIRED")
      .map(
        (device): VehicleEntry => ({
          vehicleType: device.installationData?.VehicleType,
          photo: device.installationData?.Image ? addBase64Prefix(device.installationData.Image) : undefined,
          licensePlate: device.installationData?.LicensePlateNumber,
          modelName: device.installationData?.Model,
          manufYear: device.installationData?.Year,
          deviceId: device.id,
          createdDateTime: device.installationData?.Added,
          status: getStatus(device),
          fleetName: device.fleetAssigned,
          fullData: device.installationData?.Data,
          vehicleId: device.vehicleId,
        })
      ),
  };
}
export function onClickRow(row: Row<VehicleEntry>, table: TableInstance<VehicleEntry>) {
  const isRowExpanded = row.isExpanded;
  table.toggleAllRowsExpanded(false);
  if (!isRowExpanded) {
    row.toggleRowExpanded(true);
  }

  const isRowSelected = row.isSelected;
  table.toggleAllRowsSelected(false);
  if (!isRowSelected) {
    row.toggleRowSelected(true);
  }
}
export function generateColumns(
  onClickUnmute: (vehicle: VehicleEntry) => void,
  onClickUnpair: (vehicle: VehicleEntry) => void,
  userRole?: Role
): Column<VehicleEntry>[] {
  return [
    {
      id: "checkbox",
      Cell: ({ row }: CellProps<VehicleEntry>) => (
        <CheckboxInput
          checked={row.isSelected ? "FULL" : "EMPTY"}
          onClick={() => row.toggleRowSelected(!row.isSelected)}
        />
      ),
    },
    {
      accessor: "vehicleType",
      Header: <FormattedMessage id="VEHICLE_TYPE" defaultMessage="Vehicle Type" />,
      Cell: ({ value }) => (
        <div className="d-flex align-items-center gap-4">
          <VehicleIcon name={value} size="large" />
        </div>
      ),
    },
    {
      accessor: "photo",
      Header: <></>,
      Cell: ({ value }) => (
        <div className="d-flex align-items-center gap-4">
          <PhotoWithOverlay src={value || VehiclePhotoPlaceholder} />
        </div>
      ),
    },
    {
      accessor: "licensePlate",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="vehicleId">
          <FormattedMessage id="LICENSE_PLATE" defaultMessage="License Plate" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        if (!value) return null;
        return (
          <OverlayTrigger
            placement="right"
            offset={[30, 0]}
            overlay={
              <Tooltip>
                <FormattedMessage id="GO_TO_VEHICLES_EVENTS" defaultMessage="Go to Vehicle's Events" />
              </Tooltip>
            }
          >
            <Link
              style={{ color: "black" }}
              className="cipia-blue-on-hover"
              to={`/${RouteConstants.EVENTS_LIST}`}
              state={{ filters: [filterForEventsOfLicensePlate(value)] }}
            >
              {value}
            </Link>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "modelName",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="model">
          <FormattedMessage id="MODEL" defaultMessage="Model" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "manufYear",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="year">
          <FormattedMessage id="MANUFACTURE_YEAR" defaultMessage="Manufacture year" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "deviceId",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="id">
          <FormattedMessage id="INSTALLED_DEVICE_ID" defaultMessage="Installed Device ID" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value, row }) => {
        // if (row.original.status !== "MUTED")
        //   return <div style={{ color: row.original.status === "ACTIVE" ? "black" : "lightgrey" }}>{value}</div>;
        if (!row.isSelected)
          return <div style={{ color: "lightgrey" }}>{value}</div>;
        if (userRole === Role.FleetManager || userRole === Role.FleetUser) {
          return (
            <div className="position-relative h-100 d-flex align-items-center">
              <div className="position-absolute d-flex align-items-center gap-3">
                {/* <ActionButton icon={<ReassignIcon />} onClick={() => onClickUnmute(row.original)}>
                  <FormattedMessage id="UNMUTE" defaultMessage="Unmute" />
                </ActionButton> */}
                <ActionButton icon={<UnpairIcon fill={Color.CIPIA_BLUE} />} onClick={() => onClickUnpair(row.original)}>
                  <FormattedMessage id="UNPAIR" defaultMessage="Unpair" />
                </ActionButton>
              </div>
            </div>
          );
        }
        return <div></div>;
      },
    },
    {
      accessor: "createdDateTime",
      Header: () => (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="added">
          <FormattedMessage id="CREATED_DATE_AND_TIME" defaultMessage="Created Date and Time" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        const { getFormattedLocalDate } = useFormattedLocalDate();
        return <div>{value ? getFormattedLocalDate(value) : undefined}</div>;
      },
    },
    {
      accessor: "status",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="status">
          <FormattedMessage id="STATUS" defaultMessage="Status" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) =>
        !value ? null : (
          <div className="d-flex align-items-center gap-1">
            <StatusIcon type={value} />
            {value.charAt(0) + value.slice(1).toLowerCase()}
          </div>
        ),
    },
    {
      accessor: "fleetName",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="fleetAssigned">
          <FormattedMessage id="FLEET" defaultMessage="Fleet" />
        </SortingHeaderContainer>
      ),
    },
    {
      accessor: "fullData",
      Header: <FormattedMessage id="FULL_DATA" defaultMessage="Full Data" />,
      Cell: ({
        value,
        row: {
          original: { vehicleId, deviceId },
        },
      }) => {
        if (!value) return null;
        return (
          <div className="d-flex align-items-center">
            <JsonIcon />
            <Button
              className="px-1"
              variant="link"
              onClick={() => downloadJsonFile(value, `v${vehicleId}_d${deviceId}_data`)}
            >
              Reportfile
            </Button>
          </div>
        );
      },
    },
  ];
}
