import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Column, Row } from "react-table";
import { DevicesSortFilterParamName, GetDevicesResult } from "../../../services/devices.service";
import { DriverDeviceEntry } from "./DriverVehiclesTable";
import { FormattedMessage } from "react-intl";
import VehicleIcon from "../../../icons/VehicleIcon";
import PhotoWithOverlay from "../../VehiclesTable/PhotoOverlay";
import SortingHeaderContainer from "../../SortingHeaderContainer/SortingHeaderContainer";
import ActionButton from "../../Buttons/ActionButton/ActionButton";
import { addBase64Prefix } from "../../../util/shared.util";
import VehiclePhotoPlaceholder from "../../../assets/VehiclePhotoPlaceholder.svg";
import { ReactElement, ReactNode } from "react";
import { DEVICES_CONTEXT } from "../../../context/QueryParamsContext/queryParamContextObjects";
import { Constants } from "../../../constants";
import style from "./DriverVehiclesTable.module.scss";

export function devicesToDriverVehicles(data: GetDevicesResult) {
  return {
    ...data,
    data: data.data?.map(
      (device): DriverDeviceEntry => ({
        vehicleType: device.installationData?.VehicleType,
        photo: device.installationData?.Image ? addBase64Prefix(device.installationData.Image) : undefined,
        licensePlate: device.installationData?.LicensePlateNumber,
        deviceId: device.id,
        existInDriver: false,
        driverCount: device.driverCount,
      })
    ),
  };
}

export function generateColumns(
  buttonLable?: ReactElement,
  buttonIcon?: ReactNode,
  onClick?: (vehicle: DriverDeviceEntry) => void, checkDriverCount: boolean = false
): Column<DriverDeviceEntry>[] {
  const buttonClm = (row: Row<DriverDeviceEntry>) => {
    if (onClick) {
      if (checkDriverCount && row.original.driverCount && row.original.driverCount >= Constants.MAX_DEVICE_DRIVERS) {
        return (
          <div className={`${style['max-exceeded']} d-flex justify-content-center align-items-center m-2`}>
            <span>
              <FormattedMessage id="LIMIT_EXCEEDED" defaultMessage="Limit exceeded" />
            </span>
          </div>
        );
      }
      return (
        <ActionButton disabled={false} type="button" icon={buttonIcon} onClick={() => onClick!(row.original)}>
          <>{buttonLable}</>
        </ActionButton>
      );
    } else {
      return <></>;
    }
  };

  return [
    {
      accessor: "vehicleType",
      Header: <FormattedMessage id="VEHICLE_TYPE" defaultMessage="Vehicle Type" />,
      Cell: ({ value }) => (
        <div className="d-flex align-items-center gap-4">
          <VehicleIcon name={value} size="large" />
        </div>
      ),
    },
    {
      accessor: "photo",
      Header: <FormattedMessage id="PHOTO" defaultMessage="Photo" />,
      Cell: ({ value }) => (
        <div className="d-flex align-items-center gap-4">
          <PhotoWithOverlay src={value || VehiclePhotoPlaceholder} />
        </div>
      ),
    },
    {
      accessor: "licensePlate",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="vehicleId">
          <FormattedMessage id="LICENSE_PLATE" defaultMessage="License Plate" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        if (!value) return null;
        return (
          <OverlayTrigger
            placement="right"
            offset={[30, 0]}
            overlay={
              <Tooltip>
                <FormattedMessage id="GO_TO_VEHICLES_EVENTS" defaultMessage="Go to Vehicle's Events" />
              </Tooltip>
            }
          >
            <div></div>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "deviceId",
      Header: (
        <SortingHeaderContainer<DevicesSortFilterParamName> context={DEVICES_CONTEXT} paramName="id">
          <FormattedMessage id="DEVICE_ID" defaultMessage="Device ID" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        return <div>{value}</div>;
      },
    },
    {
      accessor: "button",
      Cell: ({ row }) => {
        return buttonClm(row);
      },
    },
  ];
}
