import { Stack } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import EventIcon from "../../../icons/EventIcon";

import { useEffect, useState } from "react";
import { CellProps, Column, Row } from "react-table";
import { useFormattedLocalDate } from "../../../hooks/useFormattedLocalDate";
import useQueryEvents, { useQueryMapEvents } from "../../../hooks/useQueryEvents";
import { getEventMetadata } from "../../../util/events.util";
import { FleetEntry } from "../../FleetView/FleetView";
import { eventsQueryResultToTableData } from "../../FleetView/fleet-utils";
import Table from "../../Table/Table";
import styles from "./CurrentTrip.module.scss";
import SortingHeaderContainer from "../../SortingHeaderContainer/SortingHeaderContainer";
import { EventsSortAndFilterParamNames } from "../../../services/events.service";
import useQueryTrips from "../../../hooks/useQueryTrips";
import { Color } from "../../../constants";
import { ReactComponent as TripsIcon } from "../../../assets/icons/Trip.svg";
import { ReactComponent as SafetyEventsIcon } from "../../../assets/icons/SafetyEvents.svg";
import { Button } from "../../Button/Button";
import { EVENTS_CONTEXT } from "../../../context/QueryParamsContext/queryParamContextObjects";
import { tripsQueryResultToTableData } from "../trips-utils";

type TripEventsTableEntry = {
  dateTime?: string;
  type?: string;
  locationAddr?: string;
  messageId?: number;
};

const columns = (onClickEventType: (row: Row<TripEventsTableEntry>) => void): Column<TripEventsTableEntry>[] =>

  [
    {
      accessor: "dateTime",
      Header: (
        <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="eventDateTime">
          <FormattedMessage id="TIME" defaultMessage="Time" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value }) => {
        const { getFormattedLocalDate } = useFormattedLocalDate();
        return !value ? null : <div>{getFormattedLocalDate(value)}</div>;
      },
    },
    {
      accessor: "type",
      Header: (
        <SortingHeaderContainer<EventsSortAndFilterParamNames> context={EVENTS_CONTEXT} paramName="eventType">
          <FormattedMessage id="EVENT" defaultMessage="Event" />
        </SortingHeaderContainer>
      ),
      Cell: ({ value, row }: CellProps<TripEventsTableEntry>) => (
        <div className="d-flex align-items-center gap-2 mb-2">
          <EventIcon serverName={row.original.type} />
          <Button
            onClick={(e) => {
              //e.stopPropagation();
              onClickEventType?.(row);
            }}
            variant="link"
            className="px-0 text-nowrap text-truncate"
          >
            <div
              style={{
                color: Color.CIPIA_BLUE,
                fontWeight: 700,
                fontSize: "1.125rem",
                textAlign: "left",
              }}
            >
              {getEventMetadata(row.original.type)?.displayName}
            </div>

          </Button>
        </div>
      ),
    },
    {
      accessor: "locationAddr",
      Header: <FormattedMessage id="LOCATION" defaultMessage="Location" />,
    },
  ];

type CurrentTripProps = {
  tripId: number;
  onMapDataChange?: (data: FleetEntry[]) => void;
  onTableDataChange?: (data: FleetEntry[]) => void;

  onSetDriverTrips: (driverId: string) => void;
  selectedDataIndex?: number;
  onSelectDataIndex?: (index: number) => void;
  showEventInfo: (show: boolean) => void;
};

export default function CurrentTrip({
  tripId,
  onMapDataChange,
  onTableDataChange,
  onSetDriverTrips,
  onSelectDataIndex,
  showEventInfo
}: CurrentTripProps) {
  const { data: events, isLoading, isError } = useQueryEvents(eventsQueryResultToTableData, true, true);
  const { data: mapEvents } = useQueryMapEvents(eventsQueryResultToTableData, true, true);
  
  const { data: trips } = useQueryTrips(tripsQueryResultToTableData);
  const [tableSelectedIndex, setTableSelectedoindex] = useState(0);
  useEffect(() => {
    if (!mapEvents) return;
    onMapDataChange?.(mapEvents.data);
    showEventInfo(false);
  }, [onMapDataChange, mapEvents, showEventInfo]);

  useEffect(() => {
    if (!events) return;
    onTableDataChange?.(events.data);
  }, [onTableDataChange, events, showEventInfo]);

  function onEventClick(row: Row<TripEventsTableEntry>) {
    const idx = events?.data.findIndex(evt => evt.lastEvent?.id === row.original.messageId)
    const tableSelectedIdx = viewEvents?.findIndex(evt => evt.messageId === row.original.messageId)
    idx !== undefined &&  onSelectDataIndex?.(idx);
    tableSelectedIdx !== undefined && setTableSelectedoindex(tableSelectedIdx)
  }
  const viewEvents = events?.data?.filter(evt=> evt.lastEvent?.category === 0).map<TripEventsTableEntry>((item) => ({
    messageId: item.lastEvent?.id,
    locationAddr: item.lastEvent?.location?.address ?? "",
    type: item.lastEvent?.type ?? "",
    dateTime: item.lastEvent?.time,
  }));

  const onClickShowEventInfo = (row: Row<TripEventsTableEntry>) => {
    onEventClick(row);
    showEventInfo(true);
  }

  return (
    <Stack gap={3} className="overflow-hidden">
      <Stack gap={1} className="flex-grow-0">
        <Stack direction="horizontal" gap={2}>
          <div className="d-flex" style={{ width: "25%" }}>
            <FormattedMessage id="CURRENT_TRIP" defaultMessage="Current trip" />:
          </div>
          <span>{tripId || "--"}</span>
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <div className="d-flex" style={{ width: "25%" }}>
            <FormattedMessage id="DRIVER" defaultMessage="Driver" />:
          </div>
          {trips?.data[0]?.drivers?.map((d) => {
            return (
              <Button
                onClick={() => onSetDriverTrips(d.driverId || "")}
                className="bg-transparent border-0 text-dark p-2"
                key={`driver-${d.driverId}-{index}`}
              >
                <TripsIcon />
                <span style={{ color: Color.CIPIA_BLUE, marginLeft: "0.25rem", textDecoration: "underline" }}>
                  {
                   d.name ||
                    (<FormattedMessage id="UNREG_DRIVER" defaultMessage="Unregistered driver" />)
                  }
                </span>
              </Button>
            );
          })}
        </Stack>
        <Stack direction="horizontal" gap={2}>
          <SafetyEventsIcon />
          <FormattedMessage id="SAFETY_EVENTS" defaultMessage="Safety Events" />:<span>{viewEvents?.length}</span>
        </Stack>
      </Stack>
      <Table
        // hideHeader
        data={viewEvents || []}
        columns={columns((row) => onClickShowEventInfo(row))}
        classes={{
          wrapper: () => styles["table-wrapper"],
          table: () => styles.table,
          thead: () => styles.thead,
          th: () => styles.th,
          tr: (row) => {
            return `${row.index === tableSelectedIndex ? "selected-row-bg" : ""} cursor-pointer`;
          },
          td: () => styles.td,
        }}
        onClickRow={onEventClick}
        isLoading={isLoading}
        isError={isError}
      />
    </Stack>
  );
}