import {  UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { VersionInfo } from "../api/data-contracts";
import { softwareService } from "../services/software.service";

export function versionQuery(
): UseQueryOptions<VersionInfo, AxiosError, VersionInfo, readonly ["version"]> {
  return {
    queryKey: ["version"] as const,
    queryFn: async () =>      await softwareService.getVersions(),
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: true,
    retry: false
  };
}
 
